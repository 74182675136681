<template lang="pug">
  .meta-item(itemprop="product" itemtype="https://schema.org/Product" itemscope)
    meta(itemprop="brand" content="touriDat.com")
    meta(v-if="item.SystemItemId" itemprop="mpn" :content="item.SystemItemId")
    meta(itemprop="condition" content="new")
    template(v-if="name")
      meta(itemprop="name" :content="name")
      meta(itemprop="title" :content="name")
    meta(itemprop="description" :content="description")
    .meta-item-offer(itemprop="offers" itemtype="https://schema.org/Offer" itemscope)
      meta(v-if="price" itemprop="price" :content="price")
      template(v-if="imageUrl")
        meta(itemprop="image" :content="imageUrl")
        meta(itemprop="image_link" :content="imageUrl")
      template(v-if="url")
        meta(itemprop="url" :content="url")
        meta(itemprop="link" :content="url")
      link(itemprop="availability" :href="isAvailable ? `https://schema.org/InStock`:`https://schema.org/OutOfStock`")
      template(v-if="isItemInLiveShopping(item)")
        meta(itemprop="availabilityStarts" :content="item.AdvertisedFrom")
        meta(itemprop="availabilityEnds" :content="item.AdvertisedTill")
</template>

<script>
import { getIsItemPurchasable, isItemInLiveShopping } from "@/lib/helper/items";
import config from "@/config/config";
import { clamp } from "@/lib/helper/math";
import URLHelper from "@/lib/helper/url_helper.ts";

export default {
  name: "MetaItem",
  props: {
    item: {
      type: Object,
      required: true
    },
    hostUrl: String,
    region: String,
    hostName: String,
    description: String,
    stars: Number,
    isSuperior: Boolean,
    images: {
      type: Array
    }
  },
  setup() {
    const formatImageUrl = URLHelper.RewriteUrlWithcloudFrontTouriDatPath;
    return { isItemInLiveShopping, formatImageUrl };
  },
  computed: {
    imageUrl() {
      if (!this.images?.length || this.images.length < 1) return undefined;
      const image = this.images[0];
      if (!image?.RemoteURL) return undefined;
      return this.formatImageUrl(`${image.RemoteURL}large.jpg`);
    },
    url() {
      let hostUrl = this.hostUrl;
      if (!hostUrl && this.item?.Host) hostUrl = this.item?.Host.Url;
      if (!hostUrl) return;
      return `${config.server.host}/gastgeber/${hostUrl}#${this.item.id}`;
    },
    canonicalUrl() {
      let hostUrl = this.hostUrl;
      if (!hostUrl && this.item?.Host) hostUrl = this.item?.Host.Url;
      if (!hostUrl) return;
      return `${config.server.host}/gastgeber/${hostUrl}`;
    },
    name() {
      return (
        this.item?.CustomAttributes?.Custom_Product_Arrangement ||
        this.item?.CustomAttributes?.Name1 ||
        this.item?.CustomAttributes?.Name2 ||
        this.item?.Name
      );
    },
    price() {
      if (this.item?.CurrentPrice) return this.item.CurrentPrice;
      if (isItemInLiveShopping(this.item)) return this.item.LiveShoppingPrice;
      return this.item.DiscountedPrice;
    },
    isAvailable() {
      return getIsItemPurchasable(this.item);
    },
    discount() {
      const discountedPercent =
        ((this.item.RegularPrice - this.price) / this.item.RegularPrice) * 100;
      return clamp(0, Math.round(discountedPercent), 100);
    },
    createMetaTitle() {
      return `${this.hostName} - ${this.region} | Jetzt -${this.discount}% günstiger`;
    },
    createMetaDescription() {
      return `Testsieger DtGV✔ 100% Käuferschutz✔ | ${this.hostName} - Sie suchen Ihr passendes Angebot? Finden Sie es in der Auswahl von touriDat. Nur jetzt -${this.discount}%!`;
    },
    createMetaImageArray() {
      return this.images.map(
        (image) =>
          `${URLHelper.RewriteUrlWithcloudFrontTouriDatPath(
            image.RemoteURL
          )}large.jpg`
      );
    }
  },
  methods: {
    generateMetaInfo() {
      let metaObject = {};

      metaObject.title = this.createMetaTitle;
      metaObject.meta = [
        {
          vmid: "description",
          name: "description",
          content: this.createMetaDescription
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: this.item.MetaKeywords
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: `${this.createMetaTitle} | touriDat.com`
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: this.createMetaDescription
        },
        {
          vmid: "og:url",
          name: "og:url",
          content: this.url
        }
      ];

      if (this.createMetaImageArray.length > 0) {
        const iterator = this.createMetaImageArray.keys();
        for (const key of iterator) {
          if (key === 0) {
            metaObject.meta.push({
              vmid: "og:image",
              name: "og:image",
              content: this.createMetaImageArray[key]
            });
          } else {
            metaObject.meta.push({
              name: "og:image",
              content: this.createMetaImageArray[key]
            });
          }
        }
      }

      if (this.item.Custom_Product_VimeoURL) {
        metaObject.meta.push({
          vmid: "og:video",
          name: "og:video",
          content: this.item.Custom_Product_VimeoURL
        });
      }

      metaObject.link = [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.canonicalUrl,
        },
      ];
      return metaObject;
    }
  },
  metaInfo() {
    return this.generateMetaInfo();
  }
};
</script>

<style lang="scss" scoped>
.meta-item {
  position: absolute !important;
  display: none !important;
}
</style>
