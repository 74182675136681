<script>
import LoadingSpinnerOverlay from "@/views/components/loading/LoadingSpinnerOverlay.vue";
import EventBus from "@/event-bus";
import { WebTemplatesEnum } from "@/utils/WebTemplatesEnum.ts";
import TPWidgetCarousel from "@/views/home/partials/trustpilot/TP-WidgetCarousel.vue";
import HomeCategories from "@/views/home/partials/homeCategories/HomeCategories";
export default {
  name: "BlogView",
  components: {
    LoadingSpinnerOverlay,
    TPWidgetCarousel,
    HomeCategories
  },
  data() {
    return {
      isLoading: false
    };
  },
  async mounted() {
    // set the blog template
    EventBus.$emit("changeWebTemplateType", WebTemplatesEnum.Blog);
  },
  unmounted() {
    // reset the template
    EventBus.$emit("changeWebTemplateType", WebTemplatesEnum.Default);
  },
  destroyed() {
    // reset the template
    EventBus.$emit("changeWebTemplateType", WebTemplatesEnum.Default);
  }
};
</script>

<template lang="pug">
  #blogView
    LoadingSpinnerOverlay(v-if="isLoading")
    router-view
    .d-flex.pb-2
      TPWidgetCarousel
    .container
      HomeCategories
</template>

<style scoped lang="scss">

</style>
