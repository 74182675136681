<template lang="pug">
  .host-preview-loader
    .contents
      ContentLoader(:height='imageHeight', :width='imageWidth', :speed='2', primary-color='#dddddd', secondary-color='#ffffff')
        rect(x='0', y='0', rx='0', ry='0', :width='imageWidth', :height='imageHeight')
</template>

<script>
import {ContentLoader} from 'vue-content-loader'

export default {
  name: "HostPreviewContentLoader",
  props:{
    imageWidth:{
      type:Number,
      default:340,
    },
    imageHeight:{
      type:Number,
      default:390,
    },
  },
  components: {
    ContentLoader,
  }
}
</script>
