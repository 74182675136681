<template lang="pug">
.grid-listing.item-listing.row
  template(v-if="items && items.length > 0")
    .item(v-for="(entry,index) in items" :class="itemClass")
      slot(:entry="entry" :index="index")
  template(v-else)
    .item.loading(v-for="n in loadingSize" :class="itemClass")
      slot(name="loader")
</template>

<script>
export default {
  name: "GridListing",
  props: {
    items: Array,
    loadingSize: {
      type: Number,
      default: 18,
    },
    itemClass: {
      type: String,
      default: "col-lg-6 col-12",
    },
  },
};
</script>

<style scoped></style>
