<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TP-WidgetCarousel",
  mounted() {
    const trustbox = this.$refs.trustcarousel;
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustbox);
    }
  },
});
</script>

<template lang="pug">
  #tp-widget-carousel.container.mb-5
    .trustpilot-widget(ref="trustcarousel" data-locale="de-DE", data-template-id="53aa8912dec7e10d38f59f36", data-businessunit-id="5fb25ba2e28cd80001f22278", data-style-height="140px", data-style-width="100%", data-theme="light", data-stars="4,5", data-review-languages="de", data-text-color="#333333")
      a(href="https://de.trustpilot.com/review/touridat.com", target="_blank", rel="noopener") Trustpilot
</template>

<style scoped lang="scss">

</style>
