<template lang="pug">
  .home-categories.pb-4
    VueSlickCarousel(v-bind="sliderSettings" v-if="!isLoading")
      .px-3.mx-auto.mt-2(v-for="category in categories")
        router-link.text-decoration-none.category-card.d-block(:to="`/kategorien/${category.Url}`" v-if="category.Url")
          .host-preview(v-if="category.Host")
            .gallery.w-100
              PreviewHostGallery(:images="getImages(category)")
            .textbox
              .name.h2.text-center.m-0.py-4 {{category.Name}}
    .row(v-else)
      .col-xl-4.col-lg-6.col-12(v-for="n in 3")
        HostPreviewContentLoader
</template>

<script>
import GridListing from "@/views/components/host/listing/GridListing";
import HostPreviewContentLoader from "@/views/components/hostPreview/HostPreviewContentLoader";
import HostPreviewBox from "@/views/components/hostPreview/partials/HostPreviewBox";
import PreviewHostGallery from "@/views/components/host/gallery/PreviewHostGallery";
import VueSlickCarousel from "vue-slick-carousel";
import { HostSearch } from "@/graphql/HostSearch.ts";

export default {
  name: "HomeCategories",
  components: {
    PreviewHostGallery,
    HostPreviewContentLoader,
    HostPreviewBox,
    GridListing,
    VueSlickCarousel
  },
  setup() {
    const sliderSettings = {
      initialSlide: 1,
      waitForAnimate: true,
      centerMode: true,
      centerPadding: "0px",
      centerMargin: "0px",
      arrows: false,
      dots: true,
      autoplay: true,
      focusOnSelect: false,
      infinite: true,
      autoplaySpeed: 5000,
      slidesToShow: 3,
      slidesToScroll: 3,
      touchThreshold: 5,
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 1200,
          settings: {
            centerPadding: "10px",
            slidesToShow: 2,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            centerPadding: "15px"
          }
        },
        {
          breakpoint: 768,
          settings: {
            centerPadding: "5px",
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return { sliderSettings };
  },
  data() {
    return {
      isLoading: true,
      categories: []
    };
  },
  methods: {
    getImages(category) {
      const images = category?.Host?.Images;
      if (!images?.length) return [];
      return images.map((image) => {
        return { url: image.RemoteURL };
      });
    },
    async update() {
      this.isLoading = true;
      try {
        const categoryIds = [
          "59", // themen/aussergewoehnlicher-urlaub
          "98", // themen/wellnessreisen
          "99", // themen/romantikurlaub
          "8",  // ferienwohnungen
          "100", // deutschland/nord-and-ostsee
          "108" // deutschland/mittelgebirge
        ];
        const results = await this.$apollo.query({
          query: HostSearch.Queries.MostPopularHostsWithCategories,
          variables: {
            options: {
              sorting: "Score",
              sortDirection: "desc",
              CategoryId: categoryIds,
              pageSize: 18
            }
          }
        }).then((response) => response?.data?.hostsSearch?.documents || []);

        const mostPopularHosts = [];
        results.forEach((host) => {
          if (mostPopularHosts.find((existingHost) => existingHost.id == host.id)) return;
          const [Category] = host.Categories.filter((category) => categoryIds.includes(category.id));
          mostPopularHosts.push({
            id: host.id,
            Name: host.Name,
            Images: host.Images,
            Category
          });
        });

        /*
        results.forEach((host) => {
          if (!host?.Categories?.length) return;
          console.log({ host, categories });
          host.Categories.forEach((category) => {
            if (!categoryIds.includes(category.id) || categories.find((c) => c.id == category.id)) return;
            category.Host = host;
            categories.push(category);
          });
        });

         */
        const categories = mostPopularHosts.flatMap((host) => {
          return {
            ...host.Category,
            Host: host
          };
        }).filter((category) => categoryIds.includes(category.id));
        categories.forEach((category) => {
          if (
            this.categories.find((existingCategory) => {
              return existingCategory.id == category.id;
            })
          ) {
            return;
          }
          this.categories.push(category);
        });
      } finally {
        this.isLoading = false;
      }
    }
  },
  async mounted() {
    await this.update();
  }
};
</script>
<style lang="scss">
.home-categories {
  .category-card {
    .gallery {
      overflow: hidden;
      aspect-ratio: 4 / 3;

      .preview-host-gallery {
        transition: transform 0.4s;
      }
    }

    &:hover {
      .preview-host-gallery {
        transform: scale(1.1);
      }
    }
  }
}
</style>
<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";

.category-card {
  .name {
    transition: color 0.4s;
  }

  &:hover {
    .name {
      color: $tdays-lighter;
    }
  }
}
</style>
