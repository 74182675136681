import gql from "graphql-tag";

export const HostSearch = {
  Queries: {
    // todo: remove documents.Name
    MapHostsSearch: gql`
        #graphql
        query HostsSearch($options: SearchHostsOptions!) {
            hostsSearch(options: $options) {
                documents {
                    id
                    Name
                    Latitude
                    Longitude
                }
                totalHosts
                page
                pageSize
                maxPage
                searchCriteria {
                    value
                    count
                }
            }
        }
    `,
    HostsSearch: gql`
        #graphql
        query HostsSearch($options: SearchHostsOptions!) {
            hostsSearch(options: $options) {
                requestId
                autocorrect
                documents {
                    id
                    Name
                    Images
                    CityRegion
                    Stars
                    Url
                    Longitude
                    Latitude
                    Items {
                        id
                        RegularPrice
                        CurrentPrice
                        Nights
                        Persons
                        Catering
                    }
                }
                totalHosts
                page
                pageSize
                maxPage
                searchCriteria {
                    value
                    count
                }
            }
        }
    `,
    MostPopularHostsWithCategories: gql`
        query HostsSearch($options: SearchHostsOptions) {
            hostsSearch(options: $options) {
                documents {
                    id
                    Name
                    Images
                    Categories {
                        id
                        Name
                        Url
                    }
                }
            }
        }
    `,
    FindLocation: gql`
        #graphql
        query FindLocation($term: String!) {
            findLocation(term: $term) {
                Name
                Latitude
                Longitude
            }
        }
    `,
    Suggestions: gql`
        #graphql
        query HostSearchSuggestions($term: String!) {
            hostSearchSuggestions(term: $term)
        }
    `,
    MostSearchedTerms: gql`
        #graphql
        query MostSearchedTerms {
            mostSearchedTerms
        }
    `
  },
  Mutations: {
    SelectSearchItem: gql`
        #graphql
        mutation SelectSearchItem($query: String!, $itemId: ID!, $requestId: ID!) {
            selectSearchItem(query: $query, itemId: $itemId, requestId: $requestId)
        }
    `
  }
};
